@import "assets/scss/reset";
@import "assets/scss/variables";
@import "assets/scss/bs-override";
@import "assets/scss/common";
@import "assets/scss/chomik";

html {
  font-size: 16px;
}

body {
  font-family: 'Lato', sans-serif;
  background: $color-light;
  color: $color-primary;
  zoom: 0.95;
}