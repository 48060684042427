.logo {
  display: block;
  background: url(../images/logo_pauart_m.png) no-repeat;
  background-size: contain;
  width: 100px;
  height: 50px;
  text-indent: -10000px;
}

.top {
  padding: 15px 0;
  color: $color-primary;
  display: flex;

  .navbar {
    width: 100%;
    margin-left: 50px;
  }

  .nav-item {
    margin-right: 15px;
  }

  a {
    color: inherit;
    font-weight: 600;
    display: block;

    &.active {
      color: $color-white;
      background: $color-secondary;
      border-radius: $radius-full;
      padding: 10px 20px !important;
    }

    &.nav-link{
      text-transform: uppercase;
    }
  }

  .user {
    margin-left: auto;
    & > * {
      display: inline-block;
      margin-left: 15px;
    }
  }
}

h2 {
  font-size: 1.625rem;
  margin: 8px 15px 20px 15px;
}

h3 {
  font-size: 1.4rem;
  margin: 20px 0 15px 0;
  font-weight: 600;
  color: $color-black;
}

button + button {
  margin-left: 5px;
}

.btn-primary {
  background: $color-tertiary;
  border: none;
  font-size: 0.875rem;
  padding: 9px 20px;
}

.btn-outline-primary {
  &:hover {
    background: $color-tertiary;
    border-color: $color-tertiary;
  }
}

.artwork-sidebar {
  h3 {
    font-size: 1.125rem;
    color: $color-secondary;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    color: $color-black;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 900;
    margin-bottom: 15px;
  }

  span > i {
    margin-left: 8px;
  }

  .search {
    margin-bottom: 40px;
    input {
      width: 100%;
      background: $color-white;
      border: 1px solid $color-light-gray;
      padding: 10px 15px;
      margin-bottom: 5px;
    }

    ul {
      margin: 10px 0;
    }

    &__item {
      font-size: 0.9375rem;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 1.25rem;
      padding: 5px 10px;

      &.active {
          background: $color-secondary;
          border-radius: $radius-default;
          color: $color-white;
      }
    }
  }

  .filter {
    border-top: 1px solid $color-light-gray;
    padding-top: 15px;
    margin-bottom: 30px;

    dd {
      font-size: 0.8125rem;
      line-height: 1rem;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;


      input[type=checkbox] {
        border: $dev;
        width: 12px;
        height: 12px;
        margin: 1px 5px 0 0;
        border-radius: $radius-default;
        border-color: $color-primary;

        &:checked {
          background: $color-primary;
          position: relative;

          &:before {
            content: ' ';
            width: 4px;
            height: 4px;
            background: $color-white;
            position: absolute;
            border-radius: $radius-default;
            left: calc(50% - 2px);
            top: calc(50% - 2px);
          }
        }
      }

      &:hover {
        color: $color-black;
      }

      &.font-weight-bold {
        color: $color-black;
        margin-top: 15px;
      }
    }
  }
}

.content {
  box-shadow: 0 2px 54px 0 rgba(0,0,0,0.1);
  border-radius: 30px 0 0 0;
  padding: 15px 15px 15px 30px;
  background: $color-white;
}

.labelsbox {
  border-left: 6px solid $color-secondary;
  border-radius: 3px;
  padding-left: 15px;
  margin: 0 0 15px 15px;

  header {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 5px;
  }
}

.label {
  background: $color-yellow;
  font-size: 0.8125rem;
  padding: 7px 10px;
  margin: 0 5px 5px 0;
  border-radius: $radius-default;
  display: inline-block;

  span + i {
    margin-left: 10px;
  }
}

table.artworks {
  border: 1px solid $color-light-gray;
  border-radius: $radius-default;

  th {
    text-transform: uppercase;
    font-size:  0.6875rem;
    color: $color-black;
    font-weight: bold;
    border: none;
    vertical-align: middle;
  }

  tr {
    border-color: $color-light-gray;
    color: $color-primary;
    &:hover {
      background: $color-yellow-light;
      cursor: pointer;
    }
  }
}

.component {
  border-width: 1px !important;
  border-radius: $radius-default;
  &-header {
    font-weight: 700;
    font-size: 0.8125rem;
    padding: 5px 10px !important;
  }

  &-content {
    background: $color-white;
    padding: 10px;
  }

  .css-1rhbuit-multiValue {
    background-color: $color-yellow-medium;
  }

  .form-line {
    padding-top: 10px;
    padding-bottom: 5px;

    input[type="text"],
    input[type="number"]{
      height: 100%;
    }
  }

  .form-label {
    display: flex;
    align-items: center;
  }
}

.children-list-component.assets-list {
  position: relative;
  background: transparent;
  border: none;
  & > * {
    background: transparent;
  }

  h6 {
    text-transform: capitalize;
    font-weight: 700;
  }

  img.small-preview {
    max-width: 100% !important;
  }

  .actions {
    position: absolute;
    top: 0;
    left: 5px;
    button {
      margin: 2px;
      background: $color-white;
      width: 30px;
      height: 30px;
      padding: 0;

      i {
        margin: 0;
        font-size: 0.875rem;
      }

      &:hover {
        background: $color-tertiary;
        border-color: $color-tertiary;
        i {
          color: inherit;
        }
      }
    }
  }

  .list-group-item {
    padding: 0 0 5px 0;
    margin-bottom: 15px;
    border: none;
  }

  .list-group > div + li {
    background: transparent;
    border: none;
  }

  .filename {
    margin-left: 5px;
  }
}


table tr.deleted {
  background: #FFA07A;
}

table tr.warning-row {
  background: #FFA07A;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}