.dropdown-enabled:hover .dropdown-menu {
  display: block;
  margin: 0px;
}

.dropdown-menu:hover {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 3px;
  top: 40%;
}

.dropdown-enabled .dropdown-menu .dropdown-submenu ul.dropdown-menu {
  display: none;
}

.dropdown-enabled .dropdown-menu .dropdown-submenu:hover ul.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  margin-top: -30px;
  left: 100%;
}

@media (max-width: 992px) {
  .dropdown-menu {
    width: 50%;
  }

  .dropdown-menu .dropdown-submenu {
    width: auto;
  }
}


.pagination-react-paginate ul {
  float: right;
}

.pagination-react-paginate ul li {
  display: inline
}

.pagination-react-paginate ul li.selected a {
  color: black;
}

.pagination-react-paginate li a {

  padding: .5rem .5rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination-react-paginate li.disabled a {
  color: #999;
}

.pagination-react-paginate li:not(.disabled)  a{
  cursor: pointer;
}

.ql-editor sup {
  vertical-align: super;
}
.ql-editor sub {
  vertical-align: sub
}

.filterItem {
  margin-right: 10px;
  display: inline;
  float: left;
  width: 250px;
}

.filterItem.small {
  display: inline;
  float: left;
  width: 150px;
}

.rotate90 {
  writing-mode: vertical-rl;
}


.tox.tox-silver-sink.tox-tinymce-aux {
  display: none !important;

}

.table td button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-spaces;
}

.open-table-manager {
  display: none;
  z-index: 100;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  right: 16px;
}

.fade {
  transition: opacity .15s linear;
}

thead:hover .open-table-manager {
  display: inline;
}

td {
  word-wrap: break-word;
  word-break: break-word;
}

.mark-area {
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, .15);
  padding-top: 8px;
  padding-bottom: 8px;
}
a.nav-link {
  text-transform: uppercase;
}


.css-26l3qy-menu {
  z-index: 10000 !important;
}

.component {
  margin-bottom: 20px;
  border: 4px solid #ededed;
  border-radius: 10px;
}

.component.has-errors {
  border-color: red;
}

.component .component-header {
  background: #ededed;
  text-transform: uppercase;
  line-height: 28px;
  padding: 5px;
}


.component .component-header button {
  float: right;
  margin-top: -5px;
  border: 0px !important;
}

.lp-copy-sel__option, .lp-copy-sel__single-value, .lp-copy-sel__multi-value__label, .lp-copy-sel__multi-value--is-disabled {
  white-space: pre-wrap !important;
}

.lp-copy-sel__value-container {
  height: 5em;
}

.content-padding {
  padding: 10px;
}

.form-line {
  min-height: 50px;
}

.form-label {
  color: #999;
  font-size: 13px;
}

textarea.full-width {
  width: 100%;
}

.form-line input {
  text-indent: 10px;
  border-radius: 4px;
  border: 1px solid hsl(0,0%,80%);
}

.form-line input.regular-input {
  height: 38px;
}

.form-line input[type=text] {
  width: 100%;
}
.form-line input[type=password] {
  width: 100%;
}
.form-line input[type=number] {
  width: 100%;
}


.form-line input[type=checkbox] {
  float: left;
}

.form-line textarea {
  border-radius: 4px;
  border-color: hsl(0,0%,80%);
}

.header-actions-menu {
  margin-top: 16px;
  margin-bottom: 20px;
}

.header-actions-menu button {
  margin-right: 5px;
}

.header-actions-menu .alert {
  display: inline;
}

.main-navigation-bar {
  margin-bottom: 20px;
}

.main-navigation-bar img {
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.filter-badge {
  margin-right: 10px;
  cursor: pointer;
}


.filter-badge .delete-filter {
  margin-left: 5px;
}

.delete-filter:hover {
  color: red;
}

.filters-dropdown {
  width: 600px;
}


.fixed-size-modal-body {
  height: 400px;
}


.children-list-component {
  margin-bottom: 20px;
  border: 4px solid #ededed;
  border-radius: 10px;
}

.children-list-component h6 {
  background: #ededed;
  text-transform: uppercase;
  line-height: 28px;
  padding: 5px;
  margin-bottom: 0px;
}

.children-list-component dt {
  margin-top: -9px;
}

.children-list-component dl {
  margin-top: 5px;
  cursor: pointer;
}

.children-list-component ul li {
  padding-bottom: 0px;

}


.modal-dialog.modal-fullscreen {
  max-width: 100%;
}

.assets-list img.small-preview {
  max-width: 214px;
}


.assets-list .actions {
  cursor: pointer;
  padding-bottom: 15px;
  padding-top: 8px;
}

.item-details-left-col {
  padding-top: 15px;
}

img.img-preview-in-table {
  max-width: 64px;
  max-height: 64px;
}

.image-viewer span.filename {
  color: #6c757d;
  font-size: 10px;
  line-height: 0.5;
  word-break: break-word;
}

.assets-list .arrows-sign {
  display: none;
  float: right;
}

.assets-list li:hover .arrows-sign {
  display: inline;
}

.assets-list .fa-crop {
  margin-left: 75px;
}

.form-error {
  color: red;
}

.loginFormContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  height: 150px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 30px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}


.list-group-item.noborder {
  border: 0px;
}


.add-filter .add-filter-hidden-icon {
  visibility: hidden;
  margin-left: 10px;
  color: lightgray;
}

.add-filter:hover .add-filter-hidden-icon {
  visibility: visible;
}


/*    loading start*/


#overlay {
  position: fixed; /* Sit on top of the page content */
  /*display: none; !* Hidden by default *!*/
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

/*    loading end*/

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
  margin-bottom: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

.form-section-header {
  color: #394347;
  font-size: 14px;
}

.hide-delete-button .delete-button {
  display: none;
  height: 100%;
}

.hide-delete-button:hover .delete-button {
  display: block;
}