.btn {
  text-transform: uppercase;

  i + span {
    margin-left: 6px;
  }


}

.btn-sm {
  font-size: 0.75rem;
}

.btn-outline-primary {
  border-color: $color-tertiary;
  color: $color-tertiary;
}

input[type=checkbox] {
  width: 14px;
  height: 14px;
  -webkit-appearance: checkbox;
}