$color-primary: #394347;
$color-secondary: #D3A55D;
$color-tertiary: #224EEE;
$color-white: #fff;
$color-black: #000;
$color-light: #fafafa;
$color-light-gray: #f0f0f0;
$color-yellow: #FEEEBD;
$color-yellow-light: rgba(254,238,189,0.3);
$color-yellow-medium: rgba(254,238,189,0.54);


$radius-full: 50px;
$radius-default: 5px;
$dev: 1px solid #f00;